.screenshot-left,
.screenshot-right {
  display: block;
  margin-top: 3px; /* to make image top align with top of h2 title */
}
.screenshot-left img,
.screenshot-right img {
  border: solid black 1px;
  vertical-align: text-top;
}
.screenshot-right {
  float: right;
  margin-left: 20px;
}
.screenshot-left {
  float: left;
  margin-right: 20px;
}
.captioned-screenshot {
  color: red;
}
.caption {
  padding-top: 2px;
  font-size: 12px;
  font-weight: normal;
  font-style: italic;
  text-align: center;
}

.item-page {
  position: relative;
}
