table {
  font-size: inherit;
}
.edit-actions + h3 {
  margin-top: 22px;
}
.edit-actions {
  font-size: 90%;
  padding: 0;
  margin: 13px 0 13px 0;
}
.edit-actions tr.spacer {
  height: 10px;
}
.edit-actions td {
  padding: 0;
  white-space: nowrap;
}
.edit-actions td:nth-child(odd) {
  background-color: #ddd;
  padding: 3px 2px 3px 1px;
}
.edit-actions td:nth-child(even) {
  text-align: center;
}
.edit-actions td.description {
  padding-left: 10px;
  text-align: left;
}

.edit-buffer {
  font-family: 'Lucida Console', Monaco, monospace;
  font-size: 90%;
  white-space: pre;
  background-color: #ddd;
}

.cursor {
  z-index: 1;
  position: absolute;
  margin-top: -2px;
  margin-left: -1px;
  width: 2px;
  height: 20px;
  background-color: #777;
}
.selection {
  background-color: #7cc4f2;
}
.arrow-line:after {
  content: '→';
  font-size: 110%;
  vertical-align: -1px;
  margin-left: 9px;
  margin-right: -5px;
}
.arrow-head:before {
  content: '→';
  font-size: 110%;
  vertical-align: -1px;
  margin-left: -2px;
  margin-right: 9px;
}
.edit-action {
  font-family: 'Lucida Console', Monaco, monospace;
  font-size: 90%;
  background-color: #fff;
  border: 1px black solid;
  border-radius: 2px;
  padding: 4px 2px 2px 2px;
  white-space: nowrap;
  /*margin-left: 10px;
  margin-right: 10px;*/
}
table.key-bindings {
  margin-top: 4px;
  margin-bottom: 4px;
}
table.key-bindings code {
  white-space: nowrap;
}
table.key-bindings td {
  vertical-align: text-bottom;
  padding-top: 3px;
  padding-bottom: 3px;
}
table.key-bindings td:nth-child(2) {
  font-size: 110%;
  padding-left: 10px;
  padding-right: 10px;
}

:global {
  #atom-emulator {
    position: relative;
    width: 640px;
  }
  #atom-emulator .notification {
    position: absolute;
    border-radius: 2px;
    padding: 5px;
    top: 3px;
    right: 2px;
  }
  #atom-emulator .caption {
    padding-top: 2px;
    font-size: 90%;
    display: table;
    margin: 0 auto;
  }
  #atom-emulator .edit-buffer {
    height: 110px;
    padding: 3px;
    overflow: scroll;
  }
  .fade-in {
    animation: fadein 0.05s;
    opacity: 1;
  }
  .fade-out {
    animation: fadeout 0.1s;
    opacity: 0;
  }
  @keyframes :global(fadein) {
    // :global here gives error in VSCode, but is accepted by loaders and seems to be the only way to prevent
    // rewriting keyframes to modular identifier. See https://github.com/css-modules/css-modules/issues/115
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @keyframes :global(fadeout) {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
}
