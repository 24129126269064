// style='float: left; margin-left: 10px;'
// frameborder='0'
.restaurant {
  float: left;
}
.restaurant-frame {
  height: 685px;
  width: 382px;
  padding: 0px;
  margin: 10px 0px 0px 0px;
  background-color: white;
  border: solid black 1px;
  vertical-align: middle;
}
.client {
  float: right;
}
.client-frame {
  height: 389px;
  width: 228px;
  padding: 0px;
  margin: 10px 0px 0px 0px;
  background-color: white;
  border: solid black 1px;
  vertical-align: middle;
}
.caption {
  text-align: center;
  font-style: italic;
  margin: 4px 0 18px 0;
}
