// Global html element styles (no classes)
html,
body {
  height: 100%;
}

body {
  margin: 0;
  background: url('img/page/background-pattern-150x150.jpg');
  background-size: 150px; // don't double for retina, rough is fine
  background-color: #efece7;
  /* fall back for old/weird browsers */
  // NOTE: keep in line with Menubar.scss:.top-margin-filler (TODO: maybe abstract over this with sass)

  font-size: 14px;
  /*font-family: 'PT Sans', sans-serif;*/
  /*font-family: 'Oxygen', sans-serif;*/
  font-family: 'Merriweather Sans', sans-serif;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: subpixel-antialiased; // prevent overly thin fonts on Safari

  overflow-y: scroll; // always show scrollbar to prevent jumps caused by appearing/disappearing scrollbars
  -webkit-overflow-scrolling: touch; // enable momentum/inertia scrolling on iPhone/iPad
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

[data-debug-styles="true"] * {
  background-image: none !important;
}

/* Page-specific (putting these in Page.scss under .content-page makes it impossible to override them as .content-page is rewritten) */
code {
  color: black;
  background-color: #ddd;
}

/* don't use background when in SyntaxHighLighter */
pre>code {
  background-color: transparent;
  white-space: pre; // instead of pre-wrap
}

a {
  color: black;
  text-decoration: underline;
}

a:hover {
  color: purple;
}

h1:first-of-type {
  margin-top: 0px;
}

h1 {
  font-size: 28px;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 8px;
}

h2 {
  font-size: 21px;
  font-weight: bold;
  margin-top: 18px;
  margin-bottom: 6px;
}

h3 {
  font-size: 17px;
  font-weight: bold;
  margin-top: 16px;
  margin-bottom: 2px;
}

/* Text styles */

p {
  margin-top: 0px;
  margin-bottom: 12px;
}

p,
li {
  line-height: 24px;
}

li {
  padding-bottom: 2px;
}

/* https://google-webfonts-helper.herokuapp.com/fonts/merriweather-sans?subsets=latin */
/* merriweather-sans-300 - latin */
@font-face {
  font-family: 'Merriweather Sans';
  font-style: normal;
  font-weight: 300;
  src: local('Merriweather Sans Light'), local('MerriweatherSans-Light'),
    url('fonts/merriweather-sans-v9-latin-300.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('fonts/merriweather-sans-v9-latin-300.woff') format('woff');
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* https://google-webfonts-helper.herokuapp.com/fonts/oswald?subsets=latin */
/* oswald-300 - latin */
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 300;
  src: local('Oswald Light'), local('Oswald-Light'),
    url('fonts/oswald-v15-latin-300.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('fonts/oswald-v15-latin-300.woff') format('woff');
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* oswald-regular - latin */
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 400;
  src: local('Oswald Regular'), local('Oswald-Regular'),
    url('fonts/oswald-v15-latin-regular.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('fonts/oswald-v15-latin-regular.woff') format('woff');
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
