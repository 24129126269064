$rotatedRibbonHeight: 61px;

$ribbonWidth: 200px;
$ribbonHeight: 15px;

.build-info-ribbon-content {
  position: relative;
  width: $ribbonWidth;
  height: $ribbonHeight;
  font-size: 10px;
  top: -0.5px;
  color: white;
  user-select: none;
  cursor: default;

  &[data-build-type='dev-server'] {
    background-color: purple;
  }
  &[data-build-type='app-bundle'] {
    background-color: blue;
  }
  &[data-build-type='production'] {
    // typically not used as ribbon is not showed on production
    background-color: grey;
  }
}

.build-info-ribbon {
  position: fixed;
  z-index: 1000;
  top: calc($rotatedRibbonHeight / 2) - $ribbonHeight;
  left: calc($rotatedRibbonHeight / 2) - calc($ribbonWidth / 2);
  transform: rotate(-45deg);
  transform-origin: bottom center;
  text-align: center;
}
