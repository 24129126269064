$mobile-max-width: 768px;
$small-max-width: 992px;

.page {
  display: flex;
}

.h-spacer {
  flex-grow: 1;
}

.page-content {
  width: 700px;
  flex-shrink: 0;
  padding-top: 15px;
  color: black;
  // NOTE: any styles under .page-content cannot be overriden, as .page-content class will be rewritten
}

@media (max-width: $mobile-max-width) {
  .page-content {
    width: 100%;
    min-width: 264px;

    padding-left: 15px;
    padding-right: 15px;
  }
}

.footer {
  clear: both;
  padding-top: 20px;
}

.footer-line {
  width: 100%;
  height: 2px;
  background-color: #666;

  /* style for dotted line: ........
  margin-top: -30px;
  height: 20px;
  font-size: 40px;
  color: #888;
  font-family: helvetica;*/
  /*background-color : red;*/
}

.copyright {
  margin: 3px 4px 30px 0;
  float: right;
  color: #333;
  font-size: 12px;
}

.copyright a {
  color: #333;
  text-decoration: none;
}

[data-debug-styles="true"] {
  // .page {
  //   background-color: rgba(red, 0.2);
  // }

  .h-spacer {
    background-color: rgba(blue, 0.2);
  }

  .page-content {
    background-color: rgba(red, 0.2);
  }
}
