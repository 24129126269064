$mobile-max-width: 768px;

.details {
  line-height: 24px;
}

.business-card {
  width: 172px;
  height: 260px;
  position: relative;
  top: -32px;
  margin-right: 130px;
  float: right;
}

.description {
  font-weight: bold;
  margin-bottom: 4px;
}

@media (max-width: $mobile-max-width) {
  .business-card {
    display: none;
  }
}
