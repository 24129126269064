.button {
  height: 17pt;
  vertical-align: -4pt;
}
.annotation {
  height: 20pt;
  vertical-align: -5pt;
}
h2 {
  margin-bottom: 0;
}
