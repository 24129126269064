.avatar-container {
  margin-top: 3px;
  margin-right: 20px;
  float: left;
}
.avatar {
  width: 166px;
  height: 166px;
  border: 1px solid black;
  margin-top: 4px;
}
