$mobile-max-width: 768px;
$small-max-width: 992px;

$page-content-width: 700px;

$menu-bar-margin-top: 20px;
$menu-bar-height: 40px;
$menu-bar-height-mobile: 50px;

$navItemHeight: 30px;
$navItemPadding: calc(($menu-bar-height - $navItemHeight) / 2);

$background: url(img/page/background-pattern-150x150.jpg);
$background-color: #efece7;

$menu-background-color: #6e4c88; // fallback in case image loads slowly
$menu-background: url(img/page/menu-bar-pattern-251x41.jpg) 0 0 / 251px 41px repeat;

$item-color: #eeece0;
$active-item-color: $item-color;
$active-item-border-color: $item-color;
$child-active-border-color: #aaa;
$hover-item-border-color: #9e88b1;

$hamburger-color: $item-color;
$hamburger-active-item-color: white;
$hamburger-active-background-color: $child-active-border-color;

$navbar-logo-spacer-width-mobile: 94px;
$navbar-logo-spacer-width-small: 100px;
// NOTE: $navbar-logo-spacer-width-normal should be kept equal to $sidebarPaddedWidth in Sidebar.scss;
$navbar-logo-spacer-width-normal: 180px;

.top-margin-filler {
  // fill gap above menubar, so content doesn't show (necessary since we scroll on body)
  background: $background;
  background-color: $background-color;
  background-size: 150px; // don't double for retina, rough is fine

  /* fall back for old/weird browsers */
  // NOTE: keep in line with GlobalElts.scss:body

  position: fixed;
  top: 0;
  width: 100%;
  height: $menu-bar-margin-top;
}

.content-vertical-spacer {
  height: calc($menu-bar-margin-top + $menu-bar-height);
}

.navbar {
  display: flex;
  justify-content: flex-start;
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  margin-top: $menu-bar-margin-top;
  height: $menu-bar-height;
  background-color: $menu-background-color;
  background: $menu-background;
  user-select: none;
  z-index: 100;

  a,
  a:hover {
    text-decoration: none;
    color: inherit;
    white-space: nowrap;
  }
}

.flexible-horizontal-spacer {
  flex-grow: 1;
}

.navbar-logo {
  flex-shrink: 0;

  // Mobile
  @media (max-width: $mobile-max-width) {
    --logoBackground: url(img/page/oblogo-single-line-mono-160x77.png);
    --logoWidth: calc(160px/ 2);
    --logoHeight: calc(77px/ 2);
    --logoPaddingTop: 7px;
    --logoPaddingLeft: 14px;
    width: $navbar-logo-spacer-width-mobile;
  }

  // Small
  @media (min-width: $mobile-max-width) and (max-width: $small-max-width) {
    --logoBackground: url(img/page/oblogo-single-line-mono-160x77.png);
    --logoWidth: calc(160px / 2.2); // default size is a bit too large.
    --logoHeight: calc(77px / 2.2);
    --logoPaddingTop: 3px;
    --logoPaddingLeft: 12px;
    width: $navbar-logo-spacer-width-small;
  }

  // Normal
  @media (min-width: $small-max-width) {
    --logoBackground: url(img/page/oblogo-multi-line-color-280x153.png);
    --logoWidth: calc(280px/ 2);
    --logoHeight: calc(153px/ 2);
    --logoPaddingTop: 3px;
    --logoPaddingLeft: 15px;
    width: $navbar-logo-spacer-width-normal;
  }

  background: var(--logoBackground) no-repeat;
  background-position: var(--logoPaddingLeft) var(--logoPaddingTop);
  height: calc(var(--logoPaddingTop) + var(--logoHeight));
  background-size: var(--logoWidth); // Only sets width, keeping aspect ratio
}


.navbar-logo-spacer {
  flex-shrink: 1;

  // Mobile
  @media (max-width: $mobile-max-width) {
    width: $navbar-logo-spacer-width-mobile;
  }

  // Small
  @media (min-width: $mobile-max-width) and (max-width: $small-max-width) {
    width: $navbar-logo-spacer-width-small;
  }

  // Normal
  @media (min-width: $small-max-width) {
    width: $navbar-logo-spacer-width-normal;
  }
}

.collapsable-container {
  display: flex;
}

.nav-items {
  width: $page-content-width;

  display: flex;
  align-items: center;
  gap: 2px;

  color: $item-color;
  font-family: 'Oswald', sans-serif;
  font-weight: 300;
  font-size: 16px;
}

.nav-item-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.nav-item {

  // Mobile
  // TODO: Make these wide
  // @media (max-width: $mobile-max-width) {
  // }

  // Small
  @media (min-width: $mobile-max-width) and (max-width: $small-max-width) {
    min-width: 100px;
  }

  // Normal
  @media (min-width: $small-max-width) {
    min-width: 118px;
  }
}

.nav-item {
  display: flex;
  flex-grow: 0;
  justify-content: center;
  align-items: center;
  height: $navItemHeight;
  padding: 1px 5px 3px 5px;
  border-width: 2px;
  border-style: solid;
  border-color: transparent;

  &:hover {
    border-color: $hover-item-border-color;
  }

  &[data-child-active='true'] {
    border-color: $child-active-border-color;
  }

  &[data-active='true'] {
    border-color: $active-item-border-color;
  }
}

.nav-item-container:hover .navbar-dropdown,
.navbar-dropdown:hover {
  display: flex;
}

.navbar-dropdown {
  display: none;
  // display: flex; // for showing without hover

  position: absolute;
  margin-top: $navItemHeight;
  padding-top: $navItemPadding;
}

.navbar-dropdown-items {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  background-color: $menu-background-color;
  background: $menu-background;
  padding-top: 10px;
  padding-bottom: 14px;
  padding-left: 14px;
  padding-right: 14px;
  border-radius: 0 0 4px 4px;
}

.hamburger-button {
  display: none;
}

// Mobile
@media screen and (max-width: $mobile-max-width) {

  .navbar {
    margin-top: 0;
    padding-right: 10px;
    height: $menu-bar-height-mobile;
    justify-content: flex-start;
  }

  .content-vertical-spacer {
    height: $menu-bar-height-mobile;
  }

  .top-margin-filler {
    display: none;
  }

  .flexible-horizontal-spacer {
    display: none;
  }

  .collapsable-container {
    position: absolute;
    top: $menu-bar-height-mobile;
    overflow-y: hidden;
    width: 100%;

    &[data-is-hamburger-active="false"] {
      max-height: 0;
      transition: max-height linear;
      transition-duration: 100ms;
    }

    &[data-is-hamburger-active="true"] {
      max-height: 450px;
      transition: max-height linear;
      transition-duration: 200ms;
    }
  }

  .nav-items {
    display: flex;
    width: 100%;
    padding: 10px 0 12px 8px;
    flex-direction: column;
    gap: 12px;
    align-items: flex-start;
    border-top: 1px solid $background-color;
    background-color: $menu-background-color;
    background: $menu-background;
  }

  .nav-item-container {
    align-items: flex-start;
  }

  .nav-item {

    &[data-child-active='true'] {
      border-color: transparent;
    }

    &[data-active='true'] {
      border-color: transparent;
      color: $hamburger-active-item-color;
      font-weight: 400;
    }

    // Hover needs to override active transparent, but not active colored.
    &:hover {
      border-color: $hover-item-border-color;
    }

    // &[data-active='true'] {
    //   border-color: $active-item-border-color;
    //   ;
    // }
  }

  .navbar-dropdown {
    position: inherit;
    display: flex;
    margin: 0;
    padding: 0;

    flex-direction: column;
    align-items: flex-start;
  }

  .navbar-dropdown-items {
    align-items: flex-start;
    padding-top: 10px;
    padding-left: 24px;
    padding-bottom: 0;
  }

  .hamburger-button {
    align-self: center;
    margin-left: auto;
    margin-right: 10px;
    padding: 6px;
    height: 36px;
    width: 44px;
    flex-grow: 0;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
    border: solid 2px $hamburger-color;
    border-radius: 6px;

    &[data-active='true'] {
      background-color: $hamburger-active-background-color;
    }
  }

  .hamburger-line {
    height: 3px;
    background-color: white;
    border-radius: 2px;
  }
}

[data-debug-styles="true"] {

  .content-vertical-spacer {
    border: 2px solid grey;
  }

  .top-margin-filler {
    border: 2px solid blue;
  }

  .flexible-horizontal-spacer {
    background-color: green;
  }

  .nav-items {
    background-color: purple;
  }
}
