$small-max-width: 992px;
$sideBarY: 120px;
$sidebarWidth: 150px;
$sidebarPaddingRight: 30px;
$sidebarPaddedWidth: $sidebarWidth + $sidebarPaddingRight;
// NOTE: $sidebarPaddedWidth should be kept equal to $navbar-logo-spacer-width-normal in MenuBar.scss;

.h-spacer {
  flex-grow: 1;
}

.sidebar-container {
  display: none;
}

.sidebar-container ul {
  margin: 0;
}

// Normal
@media (min-width: $small-max-width) {
  .sidebar-container {
    display: flex;
    position: fixed;
    top: $sideBarY;
    left: 0;
    right: 0;

    // Because spacers may block pointer events, we put the .sidebar-container in front of the page and disable pointer
    // events, except on .sidebar. (If we put it behind the page, we can't keep the pointer-event rules here)
    z-index: 99;
    pointer-events: none;
  }

  .sidebar {
    pointer-events: auto;
    // background-color: yellow;
    width: $sidebarWidth;
    flex-shrink: 0;
    margin-right: $sidebarPaddingRight;
    font-family: 'Oswald', sans-serif;
    font-weight: 300;
    text-align: right;

    ul {
      list-style-type: none;
      padding: 0;

      li {
        padding-bottom: 14px;

        a {
          font-size: 18px;
          text-decoration: none;
          color: #777;
        }

        a:hover {
          color: purple;
        }
      }

      li[data-is-active='true'] a {
        color: #333;
        font-weight: 400;
      }
    }
  }

  .page-space {
    width: 700px;
    flex-shrink: 0;
    height: 1px;
  }

  .sidebar-space-left {
    width: $sidebarPaddedWidth;
    height: 1px;
    flex-shrink: 0;
  }

  .sidebar-space-right {
    width: $sidebarPaddedWidth;
    height: 1px;
    flex-shrink: 1; // When width gets smaller, only shrink the right-hand side spacer.
  }
}

[data-debug-styles="true"] {
  .h-spacer {
    background-color: rgba(red, 0.2);
  }

  .sidebar-container {
    background-color: rgba(yellow, 0.2);
  }

  .sidebar {
    background-color: rgba(green, 0.2);
  }

  .page-space {
    background-color: rgba(purple, 0.2);
  }

  .sidebar-space-left,
  .sidebar-space-right {
    background-color: red;
  }
}
