.figure-left {
  float: left;
}
.figure-right {
  float: right;
}
.figure-left,
.figure-right {
  img {
    border: solid black 2px;
  }
}

.caption {
  text-align: center;
  font-style: italic;
  padding-top: 2px;
}
