.wvFrame {
  height: 730px;
  width: 680px;
  padding: 10px;
  margin: 20px 0px 0px 0px;
  background-color: white;
  border: solid black 1px;
}
/* radio buttons are more spatious on iPad, so we increase page height to prevent scrolling */
@media only screen and (device-width: 768px) {
  .wvFrame {
    height: 780px;
  }
}

.screenshot {
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 10px;
}

.caption {
  text-align: center;
  font-style: italic;
  padding-top: 2px;
  margin-bottom: 15px;
}
